import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import OptionSelectModal from "../../OptionSelectModal";

import { Input } from "~/components/atoms/Input";
import SingleSelect, {
  SelectOptions,
} from "~/components/atoms/SelectBox/SingleSelect";
import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { OPTION_ANY } from "~/utilities/constants/common";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface IDrawerRangeProps {
  fromValue: string;
  toValue: string;
  containerClassname?: string;
  fromTitleClass?: string;
  toTitleClass?: string;
  fromWrapperClass?: string;
  toWrapperClass?: string;
  variant?: "input" | "select" | "select_modal";
  fromInputClass?: string;
  toInputClass?: string;
  fromOptions?: SelectOptions[];
  toOptions?: SelectOptions[];
  fromTitle?: string;
  toTitle?: string;
  wrapFromInputClass?: string;
  wrapToInputClass?: string;
  fromName?: string;
  toName?: string;
  onChangeValue: (value: { from: string; to: string }) => void;
}

export type TDrawerRangeValue = {
  from: string;
  to: string;
};

const classWrapInput =
  "flex items-end border-b border-color w-full pb-[19px] lg:pb-2";
const classWrapSelect = "flex items-center w-full gap-1";

const DrawerRange: React.FC<IDrawerRangeProps> = ({
  fromValue,
  toValue,
  onChangeValue,
  containerClassname,
  fromTitleClass = "",
  toTitleClass = "",
  fromInputClass = "",
  toInputClass = "",
  variant = "input",
  fromOptions = [],
  toOptions = [],
  fromWrapperClass = "",
  toWrapperClass = "",
  fromTitle = "",
  toTitle = "",
  wrapFromInputClass = "",
  wrapToInputClass = "",
  fromName = "",
  toName = "",
}) => {
  const [localValues, setLocalValue] = useState<TDrawerRangeValue>({
    from: "0",
    to: "0",
  });

  const [openSelectModal, setOpenSelectModal] = useState<{
    field: "from" | "to";
    options: SelectOptions[];
    open: boolean;
  }>({
    field: "from",
    options: fromOptions,
    open: false,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (fromValue !== localValues.from) {
      setLocalValue((pre) => ({ ...pre, from: fromValue }));
    }
    if (toValue !== localValues.to) {
      setLocalValue((pre) => ({ ...pre, to: toValue }));
    }
  }, [fromValue, toValue]);

  if (variant === "select") {
    return (
      <div
        data-name="DrawerRange"
        className={cn("flex flex-col gap-5", containerClassname)}
      >
        <div
          data-name="DrawerRange-from-container"
          className={cn("flex flex-row items-center gap-6", fromWrapperClass)}
        >
          <Typo
            tag={ETypoTag.P}
            color="text-color"
            variant={ETypoVariant.BODY_TITLE_16}
            className={cn(
              "min-w-9 font-bold capitalize lg:text-sub-title-16",
              fromTitleClass
            )}
          >
            {t("from")}
          </Typo>
          <div className={classWrapSelect}>
            <Typo
              className="translate-y-0.5 leading-4 text-color"
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.SUB_TITLE_16}
            >
              $
            </Typo>
            <SingleSelect
              placeholder={t("any")}
              data={fromOptions}
              title=""
              name={fromName}
              defaultValue={[OPTION_ANY, ...(fromOptions || [])]?.find(
                (l) => l.id === localValues.from || ""
              )}
              dropdownClass="lg:hidden"
              containerClass="flex-1"
              listboxButtonContainerClass="items-start"
              listboxButtonClass="pb-0 h-full block"
              placeholderClass="leading-[1.2]"
              placeholderProps={{
                variant: ETypoVariant.BODY_TITLE_14,
              }}
              textSelectedClass="text-body-title-14"
              placeholderContainerClass="items-start h-full"
            />
          </div>
        </div>
        <div
          data-name="DrawerRange-to-container"
          className={cn("flex flex-row items-center gap-6", toWrapperClass)}
        >
          <Typo
            tag={ETypoTag.P}
            color="text-color"
            variant={ETypoVariant.BODY_TITLE_16}
            className={cn(
              "min-w-9 font-bold capitalize lg:text-sub-title-16",
              toTitleClass
            )}
          >
            {t("to")}
          </Typo>
          <div className={classWrapSelect}>
            <Typo
              className="translate-y-0.5 leading-4 text-color"
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.SUB_TITLE_16}
            >
              $
            </Typo>
            <SingleSelect
              placeholder={t("any")}
              data={toOptions}
              title=""
              name={toName}
              defaultValue={[OPTION_ANY, ...(toOptions || [])]?.find(
                (l) => l.id === localValues.from || ""
              )}
              dropdownClass="lg:hidden"
              containerClass="flex-1"
              listboxButtonContainerClass="items-start"
              listboxButtonClass="pb-0 h-full block"
              placeholderClass="leading-[1.2]"
              placeholderProps={{
                variant: ETypoVariant.BODY_TITLE_14,
              }}
              textSelectedClass="text-body-title-14"
              placeholderContainerClass="items-start h-full"
            />
          </div>
        </div>
      </div>
    );
  }

  if (variant === "select_modal") {
    return (
      <>
        <div
          data-name="DrawerRange"
          className={cn("flex flex-col gap-5", containerClassname)}
        >
          <div
            data-name="DrawerRange-from-container"
            className={cn("flex flex-row items-center gap-6", fromWrapperClass)}
          >
            <Typo
              tag={ETypoTag.P}
              color="text-color"
              variant={ETypoVariant.BODY_TITLE_14}
              className={cn(
                "min-w-9 font-bold capitalize lg:text-sub-title-14",
                fromTitleClass
              )}
            >
              {t("from")}
            </Typo>
            <div className={cn(classWrapInput, wrapFromInputClass, "relative")}>
              <Typo
                className="translate-y-0.5 leading-4 text-color"
                tag={ETypoTag.SPAN}
                variant={ETypoVariant.SUB_TITLE_14}
              >
                $
              </Typo>
              <Input
                value={Number(localValues?.from)?.toLocaleString("en-US")}
                autoComplete="off"
                type="text"
                className={cn("h-full py-0 text-sub-title-14", fromInputClass)}
                onChange={(e) => {
                  const value = e.target.value;
                  setLocalValue((pre) => ({ ...pre, from: value }));
                  onChangeValue({ ...localValues, from: value });
                }}
                placeholder=""
              />
              <div
                className="absolute left-0 top-0 z-10 h-full w-full cursor-pointer"
                onClick={() =>
                  setOpenSelectModal({
                    open: true,
                    options: fromOptions,
                    field: "from",
                  })
                }
              />
            </div>
          </div>
          <div
            data-name="DrawerRange-to-container"
            className={cn("flex flex-row items-center gap-6", toWrapperClass)}
          >
            <Typo
              tag={ETypoTag.P}
              color="text-color"
              variant={ETypoVariant.BODY_TITLE_14}
              className={cn(
                "min-w-9 font-bold capitalize lg:text-sub-title-14",
                toTitleClass
              )}
            >
              {t("to")}
            </Typo>
            <div className={cn(classWrapInput, wrapToInputClass, "relative")}>
              <Typo
                className="translate-y-0.5 leading-4 text-color"
                tag={ETypoTag.SPAN}
                variant={ETypoVariant.SUB_TITLE_14}
              >
                $
              </Typo>
              <Input
                autoComplete="off"
                type="text"
                className={cn(
                  "h-full py-0 text-sub-title-14 leading-4",
                  toInputClass
                )}
                value={Number(localValues?.to)?.toLocaleString("en-US")}
                onChange={(e) => {
                  const value = e.target.value;
                  setLocalValue((pre) => ({ ...pre, to: value }));
                  onChangeValue({ ...localValues, to: value });
                }}
                placeholder=""
              />
              <div
                className="absolute left-0 top-0 z-10 h-full w-full cursor-pointer"
                onClick={() =>
                  setOpenSelectModal({
                    open: true,
                    options: toOptions,
                    field: "to",
                  })
                }
              />
            </div>
          </div>
        </div>
        <OptionSelectModal
          title={openSelectModal?.field === "from" ? fromTitle : toTitle}
          open={openSelectModal?.open}
          onClose={() =>
            setOpenSelectModal({
              field: "from",
              options: [],
              open: false,
            })
          }
          options={openSelectModal?.options || []}
          value={openSelectModal?.field === "from" ? fromValue : toValue}
          onChange={(value) => {
            const field = openSelectModal?.field;
            if (field === "from") {
              setLocalValue((pre) => ({ ...pre, from: value }));
              onChangeValue({ ...localValues, from: value });
            } else {
              setLocalValue((pre) => ({ ...pre, to: value }));
              onChangeValue({ ...localValues, to: value });
            }
            setOpenSelectModal({
              field: "from",
              options: [],
              open: false,
            });
          }}
        />
      </>
    );
  }

  return (
    <div
      data-name="DrawerRange"
      className={cn("flex flex-col gap-5", containerClassname)}
    >
      <div
        data-name="DrawerRange-from-container"
        className={cn("flex flex-row items-center gap-6", fromWrapperClass)}
      >
        <Typo
          tag={ETypoTag.P}
          color="text-color"
          variant={ETypoVariant.BODY_TITLE_16}
          className={cn(
            "min-w-9 font-bold capitalize lg:text-sub-title-16",
            fromTitleClass
          )}
        >
          {t("from")}
        </Typo>
        <div className={classWrapInput}>
          <Typo
            className="translate-y-0.5 leading-4 text-color"
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.SUB_TITLE_16}
          >
            $
          </Typo>
          <Input
            value={localValues.from}
            autoComplete="off"
            type="number"
            min={0}
            className={cn("h-full py-0 text-sub-title-16", fromInputClass)}
            onChange={(e) => {
              const value = e.target.value;
              setLocalValue((pre) => ({ ...pre, from: value }));
              onChangeValue({ ...localValues, from: value });
            }}
            placeholder=""
          />
        </div>
      </div>
      <div
        data-name="DrawerRange-to-container"
        className={cn("flex flex-row items-center gap-6", toWrapperClass)}
      >
        <Typo
          tag={ETypoTag.P}
          color="text-color"
          variant={ETypoVariant.BODY_TITLE_16}
          className={cn(
            "min-w-9 font-bold capitalize lg:text-sub-title-16",
            toTitleClass
          )}
        >
          {t("to")}
        </Typo>
        <div className={classWrapInput}>
          <Typo
            className="translate-y-0.5 leading-4 text-color"
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.SUB_TITLE_16}
          >
            $
          </Typo>
          <Input
            autoComplete="off"
            type="number"
            min={0}
            className={cn(
              "h-full py-0 text-sub-title-16 leading-4",
              toInputClass
            )}
            value={localValues.to}
            onChange={(e) => {
              const value = e.target.value;
              setLocalValue((pre) => ({ ...pre, to: value }));
              onChangeValue({ ...localValues, to: value });
            }}
            placeholder=""
          />
        </div>
      </div>
    </div>
  );
};

export default DrawerRange;
