import { useEffect } from "react";
import { Modal } from "react-daisyui";
import { useTranslation } from "react-i18next";

import { Icon } from "~/components/atoms/Icons";
import IconClose from "~/components/atoms/Icons/IconClose";
import { SelectOptions } from "~/components/atoms/SelectBox/SingleSelect";
import Typo from "~/components/atoms/Typo";
import ModalTop from "~/components/templates/ModalTop";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoVariant } from "~/utilities/enums/Typo";

interface IOptionSelectModalProps {
  open: boolean;
  options?: SelectOptions[];
  value: string | number;
  title: string;
  onClose: () => void;
  onChange: (value: string) => void;
}

const OptionSelectModal: React.FC<IOptionSelectModalProps> = ({
  title,
  value,
  options,
  open,
  onClose,
  onChange,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    if (open) {
      window.addEventListener("keydown", close);
    } else {
      window.removeEventListener("keydown", close);
    }
    return () => window.removeEventListener("keydown", close);
  }, [onClose, open]);

  return (
    <ModalTop
      isOpen={open}
      onClose={onClose}
      closeOnOutsideClick={true}
      className="flex w-11/12 flex-col gap-3 rounded-3xl bg-contactUsModalBackground p-8 lg:rounded-xl lg:px-[18px] lg:py-6"
      backdropClassName="bg-contactUsModalBackdrop"
    >
      <Modal.Header className="mb-0 border-b border-gableGreen25 pb-4 font-bold">
        <button
          className="flex w-full flex-row justify-between"
          onClick={() => onClose()}
        >
          <Typo
            variant={ETypoVariant.HEADER_18}
            color={ETypoColor.TEXT}
            tag="h4"
            className="flex-1 font-bold capitalize"
          >
            {title}
          </Typo>
          <IconClose />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="max-h-[60vh] overflow-y-auto overflow-x-hidden">
          <div className="flex flex-col">
            {options?.map(({ id, name }) => {
              const isActive = value === id;
              return (
                <button
                  key={`DrawerSingleSelect-${id}`}
                  className={cn(
                    "flex cursor-pointer flex-row flex-nowrap justify-between py-3"
                  )}
                  onClick={() => onChange(String(id))}
                >
                  <Typo
                    tag="p"
                    variant={ETypoVariant.SUB_TITLE_14}
                    color={isActive ? ETypoColor.SUCCESS : ETypoColor.TEXT}
                    className="font-normal"
                  >
                    {name}
                  </Typo>
                  {isActive && (
                    <Icon TypeIcon={EIcon.CheckedIcon} width={16} height={16} />
                  )}
                </button>
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </ModalTop>
  );
};

export default OptionSelectModal;
